import React, { useState, useEffect } from 'react';
import {Col, Button, Row, Input, Select,} from 'antd';
import {IdentityPurpose, identityService, DomainPlatforms} from '../../../services/identity';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
// import styles from '../ZoneStats.module.css';
import { showApiErrors } from '../../../utils/showApiErrors';
const { Option } = Select;


export const Filters = ({ onSubmit }) => {
  const [filters, setFilters] = useState({});
  const handleUpdate = () => {
    onSubmit({
      filters,
    });
  };

  return (
    <div className="">
      <div >
        <Row gutter={32}>
          <Col>
            <span>Domains</span>
            <Input
              placeholder="Search"
              value={filters.searchValue}
              onChange={(e) => {
                setFilters({ ...filters, searchValue: e.target.value });
              }}
            />
          </Col>
          <Col>
            <br />
            <Button style={{ alignSelf: 'center' }} onClick={handleUpdate} type="primary">
              Search
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
