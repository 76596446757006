import React, { useState } from 'react';
import { Table as AntTable, Pagination, Modal, Popover, Button, Checkbox } from 'antd';
import { identityService } from '../../../services/identity';
import { showApiErrors } from '../../../utils/showApiErrors';
import { openSuccessNotification } from '../../../utils/notifications';
import { AddDomainModal } from './AddDomainModal';

export const DomainsInfoTable = ({
  loading,
  identityId,
  domains,
  totalItems,
  currentPage,
  pageSize,
  onPaginationChange,
  onDelete,
  onAdd,
  onSetAutoRenew
}) => {
  const [addModalVisible, setAddModalVisible] = useState(false);

  const handleRemove = async (domain) => {
    try {
      await identityService.removeDomain(domain.id);
      openSuccessNotification({ message: 'Successfully removed domain!' });
      onDelete();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const confirmRemove = (domain) => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to remove ${domain.address}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => handleRemove(domain),
      onCancel() {}
    });
  };

  const handleAutoRenewToggle = async (domain, enable) => {
    try {
      await identityService.setAutoRenew(domain.id, enable);
      openSuccessNotification({ message: `Successfully ${enable ? 'enabled' : 'disabled'} auto-renew!` });
      onSetAutoRenew();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const confirmAutoRenewToggle = (domain, enable) => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to ${enable ? 'enable' : 'disable'} auto-renew for ${domain.address}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => handleAutoRenewToggle(domain, enable),
      onCancel() {}
    });
  };

  const columns = [
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
      width: 'auto'
    },
    {
      title: 'Is Live',
      key: 'is_live',
      dataIndex: 'is_live',
      width: 'auto',
      render: (is_live) => (is_live ? 'True' : 'False')
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 'auto'
    },
    {
      title: 'Auto-renew',
      key: 'auto_renew',
      width: 'auto',
      render: (domain) => (
        <Checkbox checked={domain.auto_renew} onChange={() => confirmAutoRenewToggle(domain, !domain.auto_renew)}></Checkbox>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (domain) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {domain.status === 'ok' && (
            <Popover content="Remove from identity" trigger="hover">
              <div style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => confirmRemove(domain)}>
                <img src="/images/delete_icon.png" alt="Remove" width="21px" />
              </div>
            </Popover>
          )}
        </div>
      )
    }
  ];

  return (
    <div>
      <div>
        <Button type="primary" onClick={() => setAddModalVisible(true)}>
          Add Domain
        </Button>
      </div>
      <AntTable
        loading={loading}
        dataSource={domains}
        style={{ width: 'auto' }}
        rowKey={({ id }) => id}
        columns={columns}
        pagination={false}
        footer={() => {
          // default pagination gets hidden if no data on current page so we must use custom (with default it's possible to get stuck on some page if for example you delete the last item on last page if it's the only item there)
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
      <AddDomainModal
        modalVisible={addModalVisible}
        identityId={identityId}
        onCancel={() => setAddModalVisible(false)}
        onAdd={() => {
          setAddModalVisible(false);
          onAdd();
        }}
      />
    </div>
  );
};
