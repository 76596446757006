import React, { useEffect, useState } from 'react';

import { Table } from './components/Table';
import { identityService } from '../../services/identity';
import { useFetch } from '../../utils/hooks';
import {Filters} from "./components/Filters";
import {SortDirection} from "../../services/statistic";

export const DomainExpiring = () => {
  const [filters, setFilters] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);
  const [sorting, setSorting] = useState([{ key: 'expiration_date', value: SortDirection.Ascending }]);

  const loadExpiringDomains = (cancelToken) =>
    identityService.getExpiringSoonDomainsPaged({ currentPage, pageSize, filters, sorting, cancelToken });

  const [
    {
      data: { results: domains, count: totalItems },
      isFetching
    },
    getExpiringSoonDomains
  ] = useFetch(loadExpiringDomains);

  useEffect(() => setCurrentPage(1), [filters.filters, pageSize, sorting]);


  useEffect(() => {
    getExpiringSoonDomains();
  }, [currentPage, filters, sorting, getExpiringSoonDomains]);
  return (
    <div>
      <h1>Domain Expiring Soon</h1>
      <Filters
        onSubmit={(value) => {
          setFilters(value);
        }}
      />
      <br />
      <br />
      <Table
        loading={isFetching}
        domains={domains}
        totalItems={totalItems}
        currentPage={currentPage}
        pageSize={pageSize}
        sorting={sorting}
        onPaginationChange={(page) => setCurrentPage(page)}
        onSortingClick={setSorting}
        onChangeAutoRenew={() => getExpiringSoonDomains()}
        onAutoRenew={() => getExpiringSoonDomains()}
      />
    </div>
  );
};
