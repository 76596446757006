import {
  AppstoreFilled,
  BarChartOutlined,
  LinkOutlined,
  BarsOutlined,
  CaretRightFilled,
  CopyrightOutlined,
  DashboardOutlined,
  LineChartOutlined,
  NotificationOutlined,
  HomeOutlined,
  GlobalOutlined,
  ApiOutlined
} from '@ant-design/icons';
import React from 'react';

import { ChartView } from '../pages/channel-chart';
import { Channels } from '../pages/channel-stats';
import { Companies } from '../pages/companies';
import { ExternalURLs } from '../pages/external-urls';
import { FlaggingStats } from '../pages/flagging-stats';
import { LandingPages } from '../pages/landing-pages';
import { Editor } from '../pages/landing-pages/Editor';
import { Lists } from '../pages/lists';
import { MediaCampaigns } from '../pages/media-campaigns';
import { Products } from '../pages/products';
import { S2SLog } from '../pages/s2s-log';
import { S2STest } from '../pages/s2s-test';
import { UrgentPanel } from '../pages/urgent-panel';
import { auth } from '../services/auth';
import { UserNotifications } from '../pages/user-notifications';
import { LeadSources } from '../pages/lead-sources';
import { SalesDash } from '../pages/sales-dash';
import { AdminDash } from '../pages/admin-dash';
import { Index } from '../pages/index';
import { CompanyTags } from '../pages/company-tags';
import { FraudReports } from '../pages/fraud-reports';
import { GPSS } from '../pages/gpss';
import { Identities } from '../pages/identities';
import { FacebookPixels } from '../pages/facebook-pixels';
import { TiktokPixels } from '../pages/tiktok-pixels';
import { DomainPurchase } from '../pages/domain-purchase';
import { DomainAutoRenew } from '../pages/domain-auto-renew';
import { DomainExpiring } from '../pages/domain-expiring';

export const appRoutes = [
  {
    title: 'Index',
    path: '/',
    component: Index,
    icon: <HomeOutlined />,
    permission_name: '',
    skipRender: true
  },
  {
    title: 'Dashboard',
    path: '/sales-dashboard',
    component: SalesDash,
    icon: <DashboardOutlined />,
    permission_name: 'view_salesdashboard'
  },
  {
    title: 'Admin Dashboard',
    path: '/admin-dashboard',
    component: AdminDash,
    icon: <DashboardOutlined />,
    permission_name: 'view_admindashboard'
  },
  {
    title: 'Media Campaigns',
    path: '/media-campaigns/:id?',
    linkPath: '/media-campaigns',
    component: MediaCampaigns,
    icon: <BarChartOutlined />,
    permission_name: 'view_mediacampaign'
  },

  {
    title: 'S2S',
    path: '/s2s',
    icon: <LineChartOutlined />,

    children: [
      {
        title: 'S2S Test',
        path: '/s2s_test',
        component: S2STest,
        permission_name: 'view_s2stest'
      },
      {
        title: 'S2S Log',
        path: '/s2s_log',
        component: S2SLog,
        permission_name: 'view_s2slogexport'
      }
    ]
  },

  {
    title: 'Lists',
    path: '/lists',
    component: Lists,
    icon: <BarsOutlined />,
    permission_name: 'view_list'
  },

  {
    title: 'Statistics',
    path: '/stubs',
    icon: <LineChartOutlined />,

    children: [
      {
        title: 'Channel stats',
        path: '/channel_stats',
        component: Channels,
        permission_name: 'view_channel'
      },
      {
        title: 'Channel chart',
        path: '/channel_chart',
        component: ChartView,
        permission_name: 'view_channel'
      },
      {
        title: 'Fraud reports',
        path: '/fraud_reports',
        component: FraudReports,
        permission_name: 'view_channel'
      }
    ]
  },

  {
    title: 'Products',
    path: '/products/:id?',
    linkPath: '/products',
    component: Products,
    icon: <CaretRightFilled />,
    permission_name: 'view_product'
  },

  {
    title: 'External URLs',
    path: '/external_urls',
    component: ExternalURLs,
    icon: <LinkOutlined />,
    permission_name: 'view_externalurl'
  },

  {
    title: 'Landing Pages',
    path: '/landing_pages',
    icon: <AppstoreFilled />,
    component: LandingPages,
    permission_name: 'view_landingpage'
  },

  {
    title: 'Editor',
    path: '/editor/:id?',
    linkPath: '/editor',
    component: Editor,
    permission_name: 'view_landingpage',
    skipRender: true,
    hideSidebarAndHeader: true
  },
  {
    title: 'Companies',
    path: '/companies/:id?',
    linkPath: '/companies',
    component: Companies,
    icon: <CopyrightOutlined />,
    permission_name: 'view_company'
  },

  {
    title: 'Company Tags',
    path: '/company-tags/',
    linkPath: '/company-tags',
    component: CompanyTags,
    icon: <CopyrightOutlined />,
    permission_name: 'view_companytags'
  },
  {
    title: 'Lead Sources',
    path: '/lead-sources/:id?',
    linkPath: '/lead-sources',
    component: LeadSources,
    icon: <CopyrightOutlined />,
    permission_name: 'view_origin'
  },

  {
    title: 'User Notifications',
    path: '/user-notifications/:id?',
    linkPath: '/user-notifications',
    component: UserNotifications,
    icon: <NotificationOutlined />,
    permission_name: 'view_publishernotification'
  },
  {
    title: 'Urgent Panel',
    path: '/urgent-panel/',
    linkPath: '/urgent-panel',
    component: UrgentPanel,
    icon: <CopyrightOutlined />,
    permission_name: 'view_urgent_panel',
    skipRender: true
  },
  {
    title: 'GPSS',
    path: '/gpss/',
    linkPath: '/gpss',
    component: GPSS,
    icon: <BarChartOutlined />,
    permission_name: 'view_gpssrule'
  },
  {
    title: 'Domains',
    path: '/domains',
    icon: <GlobalOutlined />,

    children: [
      {
        title: 'Identities',
        path: '/identities',
        component: Identities,
        permission_name: 'view_identity'
      },
      {
        title: 'Domain Purchase',
        path: '/domain-purchase/:id?',
        linkPath: '/domain-purchase/',
        component: DomainPurchase,
        permission_name: 'view_domainsintegrations'
      },
      {
        title: 'Flagging stats',
        path: '/flagging_stats',
        component: FlaggingStats,
        permission_name: 'view_flagging'
      },
      {
        title: 'Domain auto-renew',
        path: '/domain_auto_renew',
        component: DomainAutoRenew,
        permission_name: 'view_identity'
      },
      {
        title: 'Domain expiring',
        path: '/domain_expiring',
        component: DomainExpiring,
        permission_name: 'view_identity'
      }
    ]
  },
  {
    title: 'S2S integrations',
    path: '/s2s-integrations',
    icon: <ApiOutlined />,

    children: [
      {
        title: 'Facebook pixels',
        path: '/facebook-pixels',
        component: FacebookPixels,
        permission_name: 'view_facebookpixel'
      },
      {
        title: 'Tiktok pixels',
        path: '/tiktok-pixels',
        component: TiktokPixels,
        permission_name: 'view_tiktokpixel'
      }
    ]
  }
];

export const getAllowedRoutes = () => {
  const filterByPermission = (routes) => routes.filter((route) => auth.hasPermission(route.permission_name));
  const filteredRoutes = filterByPermission(appRoutes);
  return filteredRoutes.map((route) => {
    if (route.children) {
      return { ...route, children: filterByPermission(route.children) };
    }
    return route;
  });
};
