import React from 'react';
import { Table as AntTable, Button, Modal, Pagination, Space } from 'antd';
import { openSuccessNotification } from '../../../utils/notifications';
import { identityService } from '../../../services/identity';
import { showApiErrors } from '../../../utils/showApiErrors';

export const Table = ({ loading, domains, totalItems, currentPage, pageSize, onPaginationChange, onAutoRenew, onChangeAutoRenew, getRenewalReadyDomains }) => {
  const handleAutoRenew = async (domain) => {
    try {
      await identityService.autoRenewNow(domain.id, false);
      openSuccessNotification({ message: 'Successfully  auto-renewed domain!' });
      onAutoRenew();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const handleAutoRenewToggle = async (domain) => {
    try {
      await identityService.setAutoRenew(domain.id, true);
      openSuccessNotification({ message: 'Successfully enabled auto-renew!' });
      onChangeAutoRenew();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const confirmAutoRenew = (domain) => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to renew domain ${domain.address}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => handleAutoRenew(domain),
      onCancel() {}
    });
  };

  const confirmAutoRenewToggle = (domain) => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to enable auto-renew domain ${domain.address}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => handleAutoRenewToggle(domain),
      onCancel() {}
    });
  };

  const columns = [
    {
      title: 'Domain',
      dataIndex: 'address'
    },
    {
      title: 'Identity',
      dataIndex: 'identity_details',
      render: (identity_details) => identity_details?.name
    },
    {
      title: 'Renewal Date',
      dataIndex: 'renewal_date'
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expiration_date'
    },
    {
      title: 'Platform',
      dataIndex: 'platform'
    },
    {
      title: 'Account',
      dataIndex: 'account'
    },
    {
      title: 'Status',
      dataIndex: 'has_traffic',
      render: (has_traffic) => (has_traffic ? 'Active' : 'Inactive')
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (domain) => (
        <Space>
          <Button onClick={() => confirmAutoRenewToggle(domain)}>
            Set Auto Renew
          </Button>
          <Button type="primary" onClick={() => confirmAutoRenew(domain)}>
            Renew now
          </Button>
        </Space>
      )
    }
  ];

  return (
    <>
      <AntTable
        loading={loading}
        dataSource={domains}
        style={{ marginTop: '3%' }}
        rowKey={({ id }) => id}
        columns={columns}
        pagination={false}
        scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalItems}
              current={currentPage}
              onChange={onPaginationChange}
              showSizeChanger={false}
              pageSize={pageSize}
            />
          );
        }}
      />
    </>
  );
};
