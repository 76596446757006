import React, { useState, useEffect } from 'react';
import {Col, Button, Row, Input, Select,} from 'antd';
import {IdentityPurpose, identityService, DomainPlatforms} from '../../../services/identity';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import styles from '../ZoneStats.module.css';
import { showApiErrors } from '../../../utils/showApiErrors';
const { Option } = Select;


export const Filters = ({ onSubmit }) => {
  const [identity, setIdentity] = useState([]);
  const [purchase_account, setPurchaseAccount] = useState([]);

  const [purchaseAccountLoading, setPurchaseAccountLoading] = useState(false);
  const [identityLoading, setIdentityLoading] = useState(false);


  const [filters, setFilters] = useState({});

  useEffect(() => {
    const getIdentity = async () => {
      try {
        setIdentityLoading(true);
        const identity = await identityService.getAll();
        setIdentity(identity);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setIdentityLoading(false);
      }
    };

    const getPurchaseAccount = async () => {
      try {
        setPurchaseAccountLoading(true);
        const data = await identityService.getDomainPurchaseAccounts();
        setPurchaseAccount(data);
      } catch (e) {
        showApiErrors(e);
      } finally {
        setPurchaseAccountLoading(false);
      }
    };

    getIdentity();
    getPurchaseAccount();

  }, []);
  const handleUpdate = () => {
    onSubmit({
      filters,
    });
  };

  return (
    <div className="">
        <div className={styles.spanFontWeight500}>
          <Row gutter={32}>
            <Col xs={32} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Identity</span>
              <VirtualizedSelect
                options={identityService.generateOptions(identity)}
                onChange={(value) => {
                  setFilters({ ...filters, identity: value });
                }}
                multi={true}
                selectValue={filters.identity}
                placeholder={'Select identity'}
                loading={identityLoading}
              />
            </Col>
            <Col xs={32} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Purpose</span>

              <Select
                placeholder={'Select purpose'}
                style={{ width: '100%' }}
                value={filters.purpose}
                onChange={(value) => setFilters({ ...filters, purpose: value })}
                allowClear
              >
                <Option value={IdentityPurpose.WebPush}>WebPush</Option>
                <Option value={IdentityPurpose.Custom}>Custom</Option>
                <Option value={IdentityPurpose.AzureWebPush}>Azure WebPush</Option>
                <Option value={IdentityPurpose.External}>External</Option>
              </Select>
            </Col>
            <Col xs={32} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Purchase Account</span>
              <VirtualizedSelect
                options={identityService.generateCustomOptions(purchase_account)}
                onChange={(value) => {
                  setFilters({ ...filters, purchase_account: value });
                }}
                multi={true}
                selectValue={filters.purchase_account}
                placeholder={'Select account'}
                loading={purchaseAccountLoading}
              />
            </Col>

            <Col xs={32} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Platform</span>

              <Select
                placeholder={'Select platform'}
                style={{ width: '100%' }}
                value={filters.platform}
                onChange={(value) => setFilters({ ...filters, platform: value })}
                allowClear
              >
                <Option value={DomainPlatforms.namecheap}>Namecheap</Option>
                <Option value={DomainPlatforms.namesilo}>Namesilo</Option>
              </Select>
            </Col>
            <Col xs={32} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <span className={styles.spanMarginBottom}>Search</span>
              <Input
                placeholder="Search"
                value={filters.searchValue}
                onChange={(e) => {
                  setFilters({ ...filters, searchValue: e.target.value });
                }}
              />
            </Col>
            <Col xs={32} sm={24} md={12} lg={8} xl={6} xxl={4}>
              <br />
              <Button style={{ alignSelf: 'center' }} onClick={handleUpdate} type="primary">
                Search
              </Button>
            </Col>
          </Row>
        </div>
    </div>
  );
};
