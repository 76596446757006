import React, { useEffect, useState } from 'react';

import { Table } from './components/Table';
import { identityService } from '../../services/identity';
import { useFetch } from '../../utils/hooks';
import {Filters} from "../domain-expiring/components/Filters";


export const DomainAutoRenew = () => {
  const [filters, setFilters] = useState({});


  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(25);

  const loadRenewalReadyDomains = (cancelToken) =>
    identityService.getRenewalReadyDomains({ currentPage, pageSize, filters, cancelToken });

  const [
    {
      data: { results: domains, count: totalItems },
      isFetching
    },
    getRenewalReadyDomains
  ] = useFetch(loadRenewalReadyDomains);

  useEffect(() => setCurrentPage(1), [filters.filters, pageSize]);

  useEffect(() => {
    getRenewalReadyDomains();
  }, [currentPage, filters, getRenewalReadyDomains]);

  return (
    <div>
      <h1>Domain Auto-Renew</h1>

      <Filters
        onSubmit={(value) => {
          setFilters(value);
        }}
      />

      <Table
        loading={isFetching}
        domains={domains}
        totalItems={totalItems}
        currentPage={currentPage}
        pageSize={pageSize}
        onPaginationChange={(page) => setCurrentPage(page)}
        onCancelAutoRenew={() => getRenewalReadyDomains()}
      />
    </div>
  );
};
