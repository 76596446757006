import { handleOrdering } from '../utils/functions';
import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { callApi } from './api';

export const IdentityStatus = {
  Active: 'Active',
  Paused: 'Paused'
};

export const IdentityType = {
  Fixed: 'Fixed',
  Replacement: 'Replacement'
};

export const IdentityPurpose = {
  WebPush: 'WebPush',
  Custom: 'Custom',
  AzureWebPush: 'AzureWebPush',
  External: 'External',
};

export const DomainPlatforms = {
  "namecheap": "namecheap",
  "namesilo": "namesilo",
}

class IdentityService {
  async getAll({ fields = 'id,name' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/identities/${query}` });
  }

  async getPaged({ currentPage, pageSize, filters, searchValue, sorting, cancelToken }) {
    const ordering = handleOrdering(sorting);

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      type: filters.type,
      status: filters.status,
      purpose: filters.purpose,
      search: searchValue,
      ordering: ordering && ordering.join(',')
    });
    return await callApi({
      url: `/api/identities/${query}`,
      cancelToken
    });
  }

  async getPagedDomains({ id, currentPage, filters, pageSize, cancelToken }) {
    let selected_filters = null;
    if(Object.keys(filters).length !==0){
      selected_filters = filters.filters
    }
    const search_filter = (selected_filters && Object.keys(selected_filters).includes("searchValue")) ? selected_filters.searchValue : ""

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      search: search_filter,
    });

    return await callApi({
      url: `/api/identities/${id}/domains/${query}`,
      cancelToken
    });
  }

  async getAvailableDomains(identityId) {
    return await callApi({
      url: `/api/domains/get_available_domains/?identity_id=${identityId}`
    });
  }

  async create(data) {
    return await callApi({ url: '/api/identity/', method: 'POST', data });
  }

  async edit(id, data) {
    return await callApi({ url: `/api/identity/${id}/`, method: 'PUT', data });
  }

  async removeDomain(domainId) {
    return await callApi({
      method: 'POST',
      url: `/api/domains/${domainId}/remove_from_identity/`
    });
  }

  async addDomain(identityId, domainId) {
    return await callApi({
      method: 'PATCH',
      url: `/api/domains/${domainId}/`,
      data: { identity: identityId }
    });
  }

  async setAutoRenew(domainId, enable) {
    return await callApi({
      method: 'PUT',
      url: `/api/domains/${domainId}/set_auto_renew/`,
      data: { auto_renew: enable }
    });
  }

  async autoRenewNow(domainId, enable) {
    return await callApi({
      method: 'PUT',
      url: `/api/domains/${domainId}/auto_renew/`,
      data: { auto_renew: enable }
    });
  }

  async getRenewalReadyDomains({ currentPage, pageSize, filters, cancelToken }) {
    let selected_filters = null;
    if(Object.keys(filters).length !==0){
      selected_filters = filters.filters
    }

    const identity_filter = (selected_filters && Object.keys(selected_filters).includes("identity")) ? selected_filters.identity.join(',') : ""
    const purpose_filter = (selected_filters && Object.keys(selected_filters).includes("purpose")) ? selected_filters.purpose : "";
    const account_filter = (selected_filters && Object.keys(selected_filters).includes("purchase_account")) ? selected_filters.purchase_account.join(',') : ""
    const platform_filter = (selected_filters && Object.keys(selected_filters).includes("platform")) ? selected_filters.platform : ""
    const search_filter = (selected_filters && Object.keys(selected_filters).includes("searchValue")) ? selected_filters.searchValue : ""

    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      identity__in:  identity_filter,
      identity__purpose:  purpose_filter,
      account__in:  account_filter,
      platform:  platform_filter,
      search: search_filter
    });
    return await callApi({
      url: `/api/domains/get_renewal_ready_domains/${query}`,
      cancelToken
    });
  }

  async getExpiringSoonDomains({ currentPage, pageSize, cancelToken}){
    const query = getQueryString({
      ...getOffsetLimitFromPagination({currentPage, pageSize})
    });
    return await callApi({
      url: `/api/domains/get_expiring_soon_domains/${query}`,
      cancelToken
    });
  }
  async getExpiringSoonDomainsPaged({ currentPage, pageSize, filters, sorting, cancelToken }) {
    let selected_filters = null;
    if(Object.keys(filters).length !==0){
      selected_filters = filters.filters
    }

    const identity_filter = (selected_filters && Object.keys(selected_filters).includes("identity")) ? selected_filters.identity.join(',') : ""
    const purpose_filter = (selected_filters && Object.keys(selected_filters).includes("purpose")) ? selected_filters.purpose : "";
    const account_filter = (selected_filters && Object.keys(selected_filters).includes("purchase_account")) ? selected_filters.purchase_account.join(',') : ""
    const platform_filter = (selected_filters && Object.keys(selected_filters).includes("platform")) ? selected_filters.platform : ""
    const search_filter = (selected_filters && Object.keys(selected_filters).includes("searchValue")) ? selected_filters.searchValue : ""

    const ordering = handleOrdering(sorting);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      identity__in:  identity_filter,
      identity__purpose:  purpose_filter,
      account__in:  account_filter,
      platform:  platform_filter,
      search: search_filter,

      ordering: ordering && ordering.join(',')
    });

    return await callApi({
      url: `/api/domains/get_expiring_soon_domains/${query}`,
      cancelToken
    });
  }

  async getDomainPurchaseAccounts() {
    return await callApi({
      url: `/api/domain_platform_accounts/?fields=name`
    });
  }

  generateCustomOptions = (arr) => {
    const uniqueL_arr = [...new Map(arr.map(item => [item.name, item])).values()];
    return uniqueL_arr.map((option) => {
      return {
        label: option.name,
        value: option.name
      };
    });
  };

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.name,
        value: option.id
      };
    });
  };
}

export const identityService = new IdentityService();
